export default {
    image: 'NissanUtility.png',
    product: 'APU-N*',
    fileName: 'APU-N Installer.exe',
    description: 'The APU-N application simplifies Nissan vehicle programming, empowering technicians to work faster and more accurately. It helps identify the programming requirements for Nissan vehicles, verifies that you have the correct files for the job, and guides you in acquiring missing files from reliable sources.\n' +
        '\n' +
        'With its guidance, APU-N reduces errors, saves time, and ensures every programming task is completed with confidence. Whether you’re reprogramming an ECU or updating vehicle software, APU-N is the essential tool for streamlining your workflow and boosting productivity.\n'+
        '\n' +
        '*Requires an active Gmail account and Windows 10 or newer',
    buttonName: 'Subscribe',
    price: '$25/Month',
    requirements: 'To signup call 385-222-0109 or email us at mikec@oealternatives.com',
    signUpUrl: '/subscriptions/verifyCheckoutSession',
    subscriptionUrl: '/subscriptions/addNissanSubscription',
    downloadUrl: '/update',
    tutorialsUrl: '/Nissan/Tutorials',
};